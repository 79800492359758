import React, { useState, useEffect } from 'react';

function ProgressBar({ value, max, color, height }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const newProgress = (value / max) * 100;
    setProgress(newProgress);
  }, [value, max]);

  const barStyle = {
    width: `${progress}%`,
    height: height || '20px', // Default height: 20px
    backgroundColor: color || '#4caf50', // Default color: Green
    transition: 'width 0.5s ease',
  };

  return (
    <div style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '5px' }}>
      <div style={barStyle}></div>
    </div>
  );
}

export default ProgressBar;
